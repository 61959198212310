import React from 'react'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import { fontSizes } from 'utils/typography'
import SecondaryParagraph from 'components/text/secondary-paragraph'

import AddToSlackButton from 'components/buttons/add-to-slack'

const Why = () => {
  return (
    <Layout>
      <div>
        <h1>Why choose Toast?</h1>

        <p
          css={css`
            color: #333;
          `}
        >
          Toast is free until it brings value.
        </p>
        {/* <h4>Why is it called Toast?</h4> */}
        <p
          css={css`
            font-size: ${fontSizes.small1};
          `}
        >
          I like the design.
          <br />
          <i>— Matheus, Senior Backend Engineer @ Parsable</i>
        </p>
        <p
          css={css`
            font-size: ${fontSizes.small1};
          `}
        >
          I like the pictures.
          <br />
          <i>— Ashish, Senior Backend Engineer @ Parsable</i>
        </p>
        <p
          css={css`
            font-size: ${fontSizes.small1};
          `}
        >
          I can see how large or small the PR is, so I can better plan my time.
          <br />
          <i>— Rory, Senior Backend Engineer @ Parsable</i>
        </p>
        <div>
          <AddToSlackButton size={60} />
          <SecondaryParagraph>
            Toast cannot read your Slack messages.
          </SecondaryParagraph>
        </div>
      </div>
    </Layout>
  )
}

export default Why
